/*!
 * Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-sharp: 'Font Awesome 6 Sharp';
  --fa-font-sharp-regular: normal 400 1em/1 'Font Awesome 6 Sharp'; }

@font-face {
  font-family: 'Font Awesome 6 Sharp';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/dist/vendor/webfonts/fa-sharp-regular-400.woff2") format("woff2"), url("/dist/vendor/webfonts/fa-sharp-regular-400.ttf") format("truetype"); }

.fasr,
.fa-regular {
  font-weight: 400; }
