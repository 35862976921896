/*!
 * Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-display: block;
  font-weight: 400;
  src: url("/dist/vendor/webfonts/fa-brands-400.woff2") format("woff2"), url("/dist/vendor/webfonts/fa-brands-400.ttf") format("truetype"); }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-display: block;
  font-weight: 900;
  src: url("/dist/vendor/webfonts/fa-solid-900.woff2") format("woff2"), url("/dist/vendor/webfonts/fa-solid-900.ttf") format("truetype"); }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-display: block;
  font-weight: 400;
  src: url("/dist/vendor/webfonts/fa-regular-400.woff2") format("woff2"), url("/dist/vendor/webfonts/fa-regular-400.ttf") format("truetype"); }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-display: block;
  font-weight: 300;
  src: url("/dist/vendor/webfonts/fa-light-300.woff2") format("woff2"), url("/dist/vendor/webfonts/fa-light-300.ttf") format("truetype"); }

@font-face {
  font-family: 'Font Awesome 5 Duotone';
  font-display: block;
  font-weight: 900;
  src: url("/dist/vendor/webfonts/fa-duotone-900.woff2") format("woff2"), url("/dist/vendor/webfonts/fa-duotone-900.ttf") format("truetype"); }
