/*!
 * Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url("/dist/vendor/webfonts/fa-solid-900.woff2") format("woff2"), url("/dist/vendor/webfonts/fa-solid-900.ttf") format("truetype"); }

@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url("/dist/vendor/webfonts/fa-brands-400.woff2") format("woff2"), url("/dist/vendor/webfonts/fa-brands-400.ttf") format("truetype"); }

@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url("/dist/vendor/webfonts/fa-regular-400.woff2") format("woff2"), url("/dist/vendor/webfonts/fa-regular-400.ttf") format("truetype");
  unicode-range: U+F003,U+F006,U+F014,U+F016-F017,U+F01A-F01B,U+F01D,U+F022,U+F03E,U+F044,U+F046,U+F05C-F05D,U+F06E,U+F070,U+F087-F088,U+F08A,U+F094,U+F096-F097,U+F09D,U+F0A0,U+F0A2,U+F0A4-F0A7,U+F0C5,U+F0C7,U+F0E5-F0E6,U+F0EB,U+F0F6-F0F8,U+F10C,U+F114-F115,U+F118-F11A,U+F11C-F11D,U+F133,U+F147,U+F14E,U+F150-F152,U+F185-F186,U+F18E,U+F190-F192,U+F196,U+F1C1-F1C9,U+F1D9,U+F1DB,U+F1E3,U+F1EA,U+F1F7,U+F1F9,U+F20A,U+F247-F248,U+F24A,U+F24D,U+F255-F25B,U+F25D,U+F271-F274,U+F278,U+F27B,U+F28C,U+F28E,U+F29C,U+F2B5,U+F2B7,U+F2BA,U+F2BC,U+F2BE,U+F2C0-F2C1,U+F2C3,U+F2D0,U+F2D2,U+F2D4,U+F2DC; }

@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url("/dist/vendor/webfonts/fa-v4compatibility.woff2") format("woff2"), url("/dist/vendor/webfonts/fa-v4compatibility.ttf") format("truetype");
  unicode-range: U+F041,U+F047,U+F065-F066,U+F07D-F07E,U+F080,U+F08B,U+F08E,U+F090,U+F09A,U+F0AC,U+F0AE,U+F0B2,U+F0D0,U+F0D6,U+F0E4,U+F0EC,U+F10A-F10B,U+F123,U+F13E,U+F148-F149,U+F14C,U+F156,U+F15E,U+F160-F161,U+F163,U+F175-F178,U+F195,U+F1F8,U+F219,U+F27A; }
